import React from 'react';
import Layout from '../components/layout/layout';
import Joinpropme from '../components/joinpropme/joinpropme';
import StayUpdated from '../components/stayupdated/stayupdated';
import * as styles from '../components/news.module.css'
import racing from '../images/racing.png';
import propmesign from '../images/propmesign.png';
import CardsRowImg1 from '../images/cardsRowImg1.png';
import CardsRowImg2 from '../images/cardsRowImg2.png';
import CardsRowImg3 from '../images/cardsRowImg3.png';
import CardsRowDivImg2 from '../images/cardsRowDiv2.png';
import CardsRowDivImg3 from '../images/cardsRowDiv3.png';
import CardsRowDivImg4 from '../images/cardsRowDiv4.png';

class News extends React.Component{
  constructor(props){
    super(props);
    this.state={

    }
  }
  render(){
    return(
      <Layout>
        <div className={styles.cardsRow1}>
          <div className={styles.newsDiv}>
            <h1 className={styles.newsheading}>News & Updates</h1>
            <p className={styles.newsdesc}>Check out our News and Latest Updates!</p>
          </div>
          <div className="row">
            <div className="col-lg-4" id={styles.whatDivCol}>
              <div className={styles.cardsRow1Col}>
                <div className={styles.partners1}>
                  <img className={styles.partners1Img} src={CardsRowImg1} alt="hugesuccess" />
                  <div className={styles.contentsdiv}>
                    <div className={styles.partners1headerMain}>
                      <text className={styles.partners1header}>PropMe’s Peer-to-Peer Marketplace</text>
                    </div>
                    <text className={styles.partnerDesc}><p>Overview. Sports wagering in America received its wings on May 14, 2018 when the United States Supreme… Court ruled that the Professional and Amateur Sports Protection Act (PASPA), the 1992 federal law that hadPeer-to-Peer Marketplace</p> <p>The Wheeling Wheelman rode his training, talent and grit to capture his biggest victory to date, taking the prestigious 2019 Snowball Derby Monday at the 5 Flags Speedway in Pensacola, Fla. It was Braden’s first time qualifying for the race, and even that was chancy, given the circumstances.</p> <p>But to WIN? That, my friends, was remarkable.</p> <p>To quickly recap: 1. Braden was wrecked in practice Friday, destroying the right side of the car. The crew replaced the entire suspension, got the ride ready and, despite no practice with “the new car,” Braden was able to qualify for the race. 2. He qualified 30th, which, on a short track with an old, slick, asphalt surface, put his car in the crosshairs for more beatin’ and bangin’ and perhaps another wreck. 3. He went a lap down on an early pit stop. 4. He was spun by another car in the last five laps.</p> <p>But to pull himself and Team Platinum together and make a run to the front after all the issues? Braden, just 25, relied on his experience and talent. When the car went a lap down and fell to 34th, the crew gambled and took on new tires before any of the other cars. This allowed him to maneuver, dart and dodge his way to sixth, and put himself into position to make a run for the win.</p> <p>Travis Braden is fast, and he’s smart. He’s got two degrees in engineering from West Virginia University — mechanical and aerospace — and knows his car inside and out.</p> <p>“I can build a car from the ground up,” he admitted. “A lot of the drivers can’t say that.”</p></text>
                    <div className={styles.continueDiv}>
                    <a className={styles.servicelinks} href="/propmes-peer-to-peer-marketplace"><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4" id={styles.whatDivCol}>
              <div className={styles.cardsRow1Col}>
                <div className={styles.partners1}>
                  <img className={styles.partners1Img} src={CardsRowImg2} alt="hugesuccess" />
                  <div className={styles.contentsdiv}>
                    <div className={styles.partners1headerMain}>
                      <text className={styles.partners1header}>Braden Wins the Snowball</text>
                    </div>
                    <text className={styles.partnerDesc}><p>It was the most improbable finish of his career. It was wild and it was staggering, several times over. But if you’ve paid close attention, you could’ve seen this coming, because it was also methodical and surgical. It was Travis Braden showing how it’s done.</p> <p>The Wheeling Wheelman rode his training, talent and grit to capture his biggest victory to date, taking the prestigious 2019 Snowball Derby Monday at the 5 Flags Speedway in Pensacola, Fla. It was Braden’s first time qualifying for the race, and even that was chancy, given the circumstances.</p> <p>But to WIN? That, my friends, was remarkable.</p> <p>To quickly recap: 1. Braden was wrecked in practice Friday, destroying the right side of the car. The crew replaced the entire suspension, got the ride ready and, despite no practice with “the new car,” Braden was able to qualify for the race. 2. He qualified 30th, which, on a short track with an old, slick, asphalt surface, put his car in the crosshairs for more beatin’ and bangin’ and perhaps another wreck. 3. He went a lap down on an early pit stop. 4. He was spun by another car in the last five laps.</p> <p>But to pull himself and Team Platinum together and make a run to the front after all the issues? Braden, just 25, relied on his experience and talent. When the car went a lap down and fell to 34th, the crew gambled and took on new tires before any of the other cars. This allowed him to maneuver, dart and dodge his way to sixth, and put himself into position to make a run for the win.</p> <p>Travis Braden is fast, and he’s smart. He’s got two degrees in engineering from West Virginia University — mechanical and aerospace — and knows his car inside and out.</p> <p>“I can build a car from the ground up,” he admitted. “A lot of the drivers can’t say that.”</p></text>
                    <div className={styles.continueDiv}>
                    <a className={styles.servicelinks} href="/braden-wins-the-snowball"><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4" id={styles.whatDivCol}>
              <div className={styles.cardsRow1Col}>
                <div className={styles.partners1}>
                  <img className={styles.partners1Img} src={CardsRowImg3} alt="hugesuccess" />
                  <div className={styles.contentsdiv}>
                    <div className={styles.partners1headerMain}>
                      <text className={styles.partners1header}>Braden Helps Promote Virtual Racing</text>
                    </div>
                    <text className={styles.partnerDesc}><p>MCMECHEN — Pro race car driver and Wheeling native Travis Braden grew up wishing that video game technology... would be more realistic — especially when it came to racing. That time has come.</p> <p>The Wheeling Wheelman rode his training, talent and grit to capture his biggest victory to date, taking the prestigious 2019 Snowball Derby Monday at the 5 Flags Speedway in Pensacola, Fla. It was Braden’s first time qualifying for the race, and even that was chancy, given the circumstances.</p> <p>But to WIN? That, my friends, was remarkable.</p> <p>To quickly recap: 1. Braden was wrecked in practice Friday, destroying the right side of the car. The crew replaced the entire suspension, got the ride ready and, despite no practice with “the new car,” Braden was able to qualify for the race. 2. He qualified 30th, which, on a short track with an old, slick, asphalt surface, put his car in the crosshairs for more beatin’ and bangin’ and perhaps another wreck. 3. He went a lap down on an early pit stop. 4. He was spun by another car in the last five laps.</p> <p>But to pull himself and Team Platinum together and make a run to the front after all the issues? Braden, just 25, relied on his experience and talent. When the car went a lap down and fell to 34th, the crew gambled and took on new tires before any of the other cars. This allowed him to maneuver, dart and dodge his way to sixth, and put himself into position to make a run for the win.</p> <p>Travis Braden is fast, and he’s smart. He’s got two degrees in engineering from West Virginia University — mechanical and aerospace — and knows his car inside and out.</p> <p>“I can build a car from the ground up,” he admitted. “A lot of the drivers can’t say that.”</p></text>
                    <div className={styles.continueDiv}>
                      <a className={styles.servicelinks} href="/braden-helps-promote-virtual-racing"><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.cardRow2}>
          <div className="row">
            <div className="col-lg-3" id={styles.whatDivCol}>
              <div className={styles.racing}>
                <img className={styles.racingImg} src={racing} alt="hugesuccess" />
                <div className={styles.cardtext}>
                  <h2 className={styles.raceheading}>Braden Coming to McMechen to Promote VR Racing</h2>
                  <p className={styles.racingtext}>WHEELING — Travis Braden is a rising star in the world of race cars. The Wheeling native is 25 years old and is... already a two-time ARCA/CRA Super Series champion. Braden is also a major player in a movement that would change the eSports landscape in West Virginia. Possible legislation is in the talking</p>
                  <a className={styles.servicelinks} href="/braden-coming-to-mcmechen"><text className={styles.continuetext}>CONTINUE READING{'>'}</text></a>
                </div>
              </div>
            </div>
            <div className="col-lg-7" id={styles.whatDivCol}>
              <div className={styles.propmesigns}>
                <img className={styles.racingImg} src={propmesign} alt="hugesuccess" />
                <div className={styles.cardtext}>
                  <h2 className={styles.raceheading}>PropMe Signs Exclusive Deal with West Virginia Bar and Restaurant Association</h2>
                  <p className={styles.racingtext}>PR Newswire. MCMECHEN, W.V., May 9, 2019 /PRNewswire/ -- PropMe, LLC (PropMe) and the WV Bar and Restaurant Association (WVRBA) announced today a new multi-year partnership that... will focus on making PropMe the exclusive official sports wagering… partner at up-to 3,200 eating and drinking locations throughout the state.</p>
                  <a className={styles.servicelinks} href="/propme-signs-exclusive-deal"><text className={styles.continuetext}>CONTINUE READING{'>'}</text></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.cardsRow1}>
          <div className="row">
            <div className="col-lg-4" id={styles.whatDivCol}>
              <div className={styles.cardsRow1Col}>
                <div className={styles.partners1}>
                  <img className={styles.partners1Img} src={CardsRowDivImg2} alt="hugesuccess" />
                  <div className={styles.contentsdiv}>
                    <div className={styles.partners1headerMain}>
                      <text className={styles.partners1header}>Jeffrey V. Kessler Joins ProGame Sports</text>
                    </div>
                    <text className={styles.partnerDesc}><p>ProGame Sports is proud and excited to welcome attorney and long-standing public servant Jeff Kessler to the... ProGame team. Jeff has recently joined ProGame as its legal counsel to provide his first rate</p> <p>The Wheeling Wheelman rode his training, talent and grit to capture his biggest victory to date, taking the prestigious 2019 Snowball Derby Monday at the 5 Flags Speedway in Pensacola, Fla. It was Braden’s first time qualifying for the race, and even that was chancy, given the circumstances.</p> <p>But to WIN? That, my friends, was remarkable.</p> <p>To quickly recap: 1. Braden was wrecked in practice Friday, destroying the right side of the car. The crew replaced the entire suspension, got the ride ready and, despite no practice with “the new car,” Braden was able to qualify for the race. 2. He qualified 30th, which, on a short track with an old, slick, asphalt surface, put his car in the crosshairs for more beatin’ and bangin’ and perhaps another wreck. 3. He went a lap down on an early pit stop. 4. He was spun by another car in the last five laps.</p> <p>But to pull himself and Team Platinum together and make a run to the front after all the issues? Braden, just 25, relied on his experience and talent. When the car went a lap down and fell to 34th, the crew gambled and took on new tires before any of the other cars. This allowed him to maneuver, dart and dodge his way to sixth, and put himself into position to make a run for the win.</p> <p>Travis Braden is fast, and he’s smart. He’s got two degrees in engineering from West Virginia University — mechanical and aerospace — and knows his car inside and out.</p> <p>“I can build a car from the ground up,” he admitted. “A lot of the drivers can’t say that.”</p></text>
                    <a className={styles.servicelinks} href="/jeffrey-v-kessler-joins-progame-sports"><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4"  id={styles.whatDivCol}>
              <div className={styles.cardsRow1Col}>
                <div className={styles.partners1}>
                  <img className={styles.partners1Img} src={CardsRowDivImg3} alt="hugesuccess" />
                  <div className={styles.contentsdiv}>
                    <div className={styles.partners1headerMain}>
                      <text className={styles.partners1header}>‘Fortnite’ Fever Gaining Foothold in the Ohio Valley</text>
                    </div>
                    <text className={styles.partnerDesc}><p>McMECHEN — While some at sports bars Wednesday evening across the Ohio Valley watched the Pirates play… the Cubs, Jerry’s Bar was playing host to the local e-sports scene with a Fortnite: Battle Royale tournament.</p> <p>The Wheeling Wheelman rode his training, talent and grit to capture his biggest victory to date, taking the prestigious 2019 Snowball Derby Monday at the 5 Flags Speedway in Pensacola, Fla. It was Braden’s first time qualifying for the race, and even that was chancy, given the circumstances.</p> <p>But to WIN? That, my friends, was remarkable.</p> <p>To quickly recap: 1. Braden was wrecked in practice Friday, destroying the right side of the car. The crew replaced the entire suspension, got the ride ready and, despite no practice with “the new car,” Braden was able to qualify for the race. 2. He qualified 30th, which, on a short track with an old, slick, asphalt surface, put his car in the crosshairs for more beatin’ and bangin’ and perhaps another wreck. 3. He went a lap down on an early pit stop. 4. He was spun by another car in the last five laps.</p> <p>But to pull himself and Team Platinum together and make a run to the front after all the issues? Braden, just 25, relied on his experience and talent. When the car went a lap down and fell to 34th, the crew gambled and took on new tires before any of the other cars. This allowed him to maneuver, dart and dodge his way to sixth, and put himself into position to make a run for the win.</p> <p>Travis Braden is fast, and he’s smart. He’s got two degrees in engineering from West Virginia University — mechanical and aerospace — and knows his car inside and out.</p> <p>“I can build a car from the ground up,” he admitted. “A lot of the drivers can’t say that.”</p></text>
                    <a className={styles.servicelinks} href="/fortnite-fever"><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4" id={styles.whatDivCol}>
              <div className={styles.cardsRow1Col}>
                <div className={styles.partners1}>
                  <img className={styles.partners1Img} src={CardsRowDivImg4} alt="hugesuccess" />
                  <div className={styles.contentsdiv}>
                    <div className={styles.partners1headerMain}>
                      <text className={styles.partners1header}>Blockchain: Peer-to-Peer Betting Marketplace</text>
                    </div>
                    <text className={styles.partnerDesc}><p>Can blockchain technology be used for gambling? Of course, yes. In fact, we’ve seen a great deal of movement… towards blockchain over the past year for online casino games. There are many variations on the theme and application for casino-based games.</p> <p>The Wheeling Wheelman rode his training, talent and grit to capture his biggest victory to date, taking the prestigious 2019 Snowball Derby Monday at the 5 Flags Speedway in Pensacola, Fla. It was Braden’s first time qualifying for the race, and even that was chancy, given the circumstances.</p> <p>But to WIN? That, my friends, was remarkable.</p> <p>To quickly recap: 1. Braden was wrecked in practice Friday, destroying the right side of the car. The crew replaced the entire suspension, got the ride ready and, despite no practice with “the new car,” Braden was able to qualify for the race. 2. He qualified 30th, which, on a short track with an old, slick, asphalt surface, put his car in the crosshairs for more beatin’ and bangin’ and perhaps another wreck. 3. He went a lap down on an early pit stop. 4. He was spun by another car in the last five laps.</p> <p>But to pull himself and Team Platinum together and make a run to the front after all the issues? Braden, just 25, relied on his experience and talent. When the car went a lap down and fell to 34th, the crew gambled and took on new tires before any of the other cars. This allowed him to maneuver, dart and dodge his way to sixth, and put himself into position to make a run for the win.</p> <p>Travis Braden is fast, and he’s smart. He’s got two degrees in engineering from West Virginia University — mechanical and aerospace — and knows his car inside and out.</p> <p>“I can build a car from the ground up,” he admitted. “A lot of the drivers can’t say that.”</p></text>
                    <a className={styles.servicelinks} href="/blockchain"><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Joinpropme />
        <StayUpdated />
      </Layout>
    )
  }
}

export default News