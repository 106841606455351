// extracted by mini-css-extract-plugin
export var newsDiv = "news-module--newsDiv--nmTrD";
export var newsheading = "news-module--newsheading--3Z9tC";
export var newsdesc = "news-module--newsdesc--1Q8u9";
export var cardsRow1 = "news-module--cardsRow1--2TFWv";
export var racing = "news-module--racing--2KTmt";
export var racingImg = "news-module--racingImg--2fU__";
export var raceheading = "news-module--raceheading--1O6aI";
export var cardRow2 = "news-module--cardRow2--g44QR";
export var racingtext = "news-module--racingtext--1xUV5";
export var continuetext = "news-module--continuetext--1w5Pv";
export var propmesigns = "news-module--propmesigns--2k9qP";
export var cardsRow1Col = "news-module--cardsRow1Col--2Bd4Z";
export var partners1 = "news-module--partners1--3xC3d";
export var continueDiv = "news-module--continueDiv--w6idj";
export var contentsdiv = "news-module--contentsdiv--GLyPX";
export var partners1Img = "news-module--partners1Img--3TtQm";
export var partnerDesc = "news-module--partnerDesc--JrWas";
export var continuetext1 = "news-module--continuetext1--1-TTx";
export var partners1headerMain = "news-module--partners1headerMain--jySRH";
export var imgsDiv = "news-module--imgsDiv--1MA3q";
export var partners1header = "news-module--partners1header--2gkop";
export var cardtext = "news-module--cardtext--264KT";
export var whatDivCol = "news-module--whatDivCol--3mv5U";